import React from 'react'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from '../lib/helpers'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import FestivalBanner from '../components/festival-banner'
//import FestivalBannerTrevor from '../components/festival-banner-trevor'
import FestivalEvents from '../components/festival-events'
//import FestivalCall from '../components/festival-call'
//import FestivalLocations from '../components/festival-locations'

function Festival (props) {

const {data} = props
const site = (data || {}).site
const festival = (data || {}).festival

const locations = (data || {}).locations
    ? mapEdgesToNodes(data.locations)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

const events = (data || {}).events
    ? mapEdgesToNodes(data.events)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

return (

  <Layout 
    title={site.title} 
    email={site.email} 
    footer={site.footer} 
    partners={festival.partners} 
    colorA={festival.colorA} 
    colorB={festival.colorB} 
    colorC={festival.colorC} 
    bodyFont={festival.bodyFont} 
    secondaryFont={festival.secondaryFont} 
    horizontalLogo={festival.horizontalLogo}
    ccLink={site.ccLink} 
  >
    <SEO
      title={site.title}
      image={festival.siteImage}
      author={site.author}
      description={site.description}
      keywords={site.keywords}
    />
    <Container>
      {festival._rawIntroduction && festival.beginDate && festival.endDate && (
        <FestivalBanner
          year={festival.title}
          intro={festival._rawIntroduction}
          beginDate={festival.beginDate}
          endDate={festival.endDate}
        />
      )}
      {events && (
        <FestivalEvents
          events={events}
        />
      )}

    </Container>
  </Layout>


)}

export default Festival