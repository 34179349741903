import React from 'react'
import Container from '../components/container'
import SEO from '../components/seo'
import LayoutComingSoon from '../containers/layout-coming-soon'
import FestivalComingSoon from '../components/festival-coming-soon'

function Festival (props) {

const {data} = props
const site = (data || {}).site
const festival = (data || {}).festival

return (

  <LayoutComingSoon 
    title={site.title} 
    email={site.email} 
    footer={site.footer} 
    partners={festival.partners} 
    colorA={festival.colorA} 
    colorB={festival.colorB} 
    colorC={festival.colorC} 
    bodyFont={festival.bodyFont} 
    secondaryFont={festival.secondaryFont} 
    horizontalLogo={festival.horizontalLogo}
    ccLink={site.ccLink} 
  >
      <SEO
        title={site.title}
        image={festival.siteImage}
        author={site.author}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
        
        {festival._rawComingSoon && (
          <FestivalComingSoon
            intro={festival._rawComingSoon}
            stackedLogo={festival.stackedLogo}
            links={festival.links}
            pdf={festival.pdf}
          />
        )}

      </Container>
    </LayoutComingSoon>


)}

export default Festival