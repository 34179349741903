import React from 'react'
import {graphql} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import Layout from '../containers/layout'
import Festival from '../components/festival'
import ComingSoon from '../components/coming-soon'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      metadata {
        dimensions {
          aspectRatio
        }
        lqip
      }
      _id
    }
  }
  query IndexPageQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      comingSoon
      email
      author
      keywords
    }

    events: allSanityEvent(
      sort: { fields: [beginDate], order: ASC }
      filter: {festivalYear: {id: {eq: $id}}}
    ) {
      edges {
        node {
          beginDate
          title
          festivalYear {
            title
          }
          url {
            title
            url
          }
          location
          slug {
            current
          }
          _rawIntro
          endDate
        }
      }
    }

    locations: allSanityLocations (
      sort: { fields: [title], order: ASC }
      filter: {festivalYear: {id: {eq: $id}}}
    ) {
      edges {
        node {
          title
          long
          lat
        }
      }
    }

    festival: sanityYear(id: {eq: $id}) {
      _rawIntroduction
      _rawComingSoon
      _rawCall
      title
      beginDate
      endDate
      horizontalLogo {
        ...SanityImage
        alt
      }
      stackedLogo {
        ...SanityImage
        alt
      }
      siteImage {
        ...SanityImage
        alt
      }
      colorA
      colorB
      colorC
      bodyFont
      secondaryFont
      links {
        title
        url
      }
      partners {
        title
        url
        image {
          ...SanityImage
          alt
        }
      }
    }

  }
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  

  if (site.comingSoon) {
    
    return (
      <ComingSoon data={data} />
    )

  } else {

    return (
      <Festival data={data} />
    )

  }

}

export default IndexPage