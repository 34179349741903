import React from "react"
import Helmet from 'react-helmet'
import '../styles/layout-coming-soon.css'

const Layout = ({children, catNodes, title, email, footer, partners, ccLink, colorA, colorB, colorC, bodyFont, secondaryFont}) => (
  <>
  	<Helmet
	    bodyAttributes={{
	        class: 'coming-soon'
	    }}
	    style={[{
          "cssText": `
              :root {
                --loco-red: `+colorA+`;
                --loco-blue: `+colorB+`;
                --loco-yellow: `+colorC+`;
                --body-font: `+bodyFont+`;
                --secondary-font: `+secondaryFont+`;
              }
          `
      }]}
	/>
    {children}
  </>
)

export default Layout