import React from 'react'
import LayoutComingSoon from '../components/layout-coming-soon'

function LayoutContainer (props) {
  return (
    <LayoutComingSoon
      {...props}
    />
  )
}

export default LayoutContainer