import React from 'react'
import {format} from 'date-fns'
import "../styles/festival-banner.css"
import {Link} from 'gatsby'
import PortableText from './portableText'
import FestivalParticles from "./festival-particles";

import Bg from "../images/svg/banner-bg-2022.svg";

function FestivalBanner (props) {
const {intro, beginDate, endDate, year} = props

return (

  <div id="festival-banner">  
    <div className="banner-particles-holder">
      <FestivalParticles
        year={year}  
      />
    </div>
  
  {year && year !== '2021' && (
  <div className="bg-holder">
    <Bg />
  </div>
  )}

  <div className="festival-banner-outer">
  <div className="wrapper">
    <div id="festival-banner-inner">
      <div id="festival-intro">
        {beginDate && endDate && (
          <div className="festival-date-holder">
            <p><span className="dh">{format(new Date(beginDate), 'MMMM d')} – {format(new Date(endDate), 'MMMM d, yyyy')}</span></p> 
          </div>
        )}
        {intro && (
          <div className="festival-intro-holder">
            <PortableText blocks={intro} />
          </div>
        )}
        <p className="more-banner"><Link to="/about">About the festival</Link></p>
      </div>
    </div>
  </div>
  </div>
  
  </div>

)}

export default FestivalBanner
