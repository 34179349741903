import React from 'react'
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

function FestivalParticles(props) {
  const particlesInit = (engine) => {
    loadFull(engine);
  };

  const {year} = props

  var imgs


  if(year === '2021'){

  imgs = [
                      {src: '/images/cross.svg', height: 15, width: 15},
                      {src: '/images/star.svg', height: 15, width: 15},
                  ]

  } else {
  
  imgs = [
                      {src: '/images/cross-a.svg', height: 15, width: 15},
                      {src: '/images/cross-b.svg', height: 15, width: 15},
                      {src: '/images/cross-c.svg', height: 15, width: 15},
                  ]

  }

  return (

    <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          background: {
            color: {
              value: "transparent",
            },
          },
          fpsLimit: 60,
          particles: {
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 5,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 400,
              },
              value: 10,
            },
            opacity: {
              value: 1,
            },
            shape: {
              type: 'images',
                  image: imgs
            },
            size: {
              random: true,
              value: 20,
            },
          },
          detectRetina: true,
            "fullScreen": {
            "zIndex": 0,
            "enable": false // this is the line to change
          },
        }}
      />

  );
}

export default FestivalParticles