import React from 'react'
import "../styles/festival-coming-soon.css"
import PortableText from './portableText'
//import Logo from "../images/svg/loco-logo.svg";
import FestivalParticles from "./festival-particles";
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

function FestivalComingSoon (props) {
const {intro, links, pdf, stackedLogo} = props

return (

  <div id="festival-coming-soon">
  <div className="tsparticles-holder">
    <FestivalParticles />
  </div>
  <div className="wrapper">
  <div id="festival-coming-soon-inner" className="grid">
    <div id="festival-coming-soon-intro" className="width-12-12-m width-12-12">
      <div className="festival-coming-soon-intro-holder">
        <div className="festival-coming-soon-logo">
          <img
            src={imageUrlFor(buildImageObj(stackedLogo))
            .url()}
            alt={stackedLogo.alt}
            title={stackedLogo.alt}
          />
        </div>
        
        {intro && (
          <div className="intro">
            <PortableText blocks={intro} />
          </div>
        )}

        {pdf && 
          <div className="pdf">
          {pdf && pdf.file && 
              <p className="extras"><a href={pdf.file.asset.url} rel="noopener noreferrer" target="_blank">{pdf.title} (pdf)</a></p>
          }
          </div>
        } 
        
        {links &&
          links.map(link => (
          <p class="festival-coming-soon-link">
            <a target="_blank" rel="noreferrer" title={link.title} href={link.url}>
              {link.title}
            </a>
          </p>    
          ))
        }  
      </div>
    </div>
  </div>
  </div>
  </div>

)}

export default FestivalComingSoon